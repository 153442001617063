import React from "react";
import "./missao.css";
import MENINO from "../../assets/img/missao/menino-missao.svg";

const Missao = () => {
  return (
    <section id="missao">
      <div className="container missao-container">
        <div className="missao-img">
          <img src={MENINO} alt="crianças estudando em sala de aula" />
        </div>
        <div className="missao-txt">
          <p>Nossa missão</p>
          <small>
            Queremos levar o conhecimento matemático para todas as crianças e adolescentes de forma divertida e
            envolvente. Nosso objetivo é despertar o interesse dos alunos e estimular sua capacidade de resolver
            problemas matemáticos desafiadores.
          </small>
        </div>
      </div>
    </section>
  );
};

export default Missao;
