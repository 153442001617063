import React from "react";
import "./colecao.css";

import { PiBookOpenText, PiSmileyWink, PiUsersThree } from "react-icons/pi";

const Colecao = () => {
  return (
    <section id="colecao">
      <div className="container colecao-container">
        <div className="colecao-title">
          <h2>A coleção Conte com a Matemática:</h2>
        </div>
        <div className="colecao-content">
          <div className="colecao-item" id="ajuda">
            <PiBookOpenText size={80} color="#3E8DF0" />
            <p>
              Ajuda o <span>desenvolvimento do pensar matemático</span>
            </p>
          </div>
          <div className="colecao-item" id="oferece">
            <PiSmileyWink size={80} color="#F24076" />
            <p>
              Oferece uma proposta <span>divertida, desafiadora e diferente</span>
            </p>
          </div>
          <div className="colecao-item" id="centenas">
            <PiUsersThree size={80} color="#F48C5F" />
            <p>
              Centenas de atividades <span>para as crianças e suas famílias</span>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Colecao;
