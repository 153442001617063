import React from "react";
import "./imagens-conte.css";

import FOTO01 from "../../assets/img/imagens-conte/foto01.svg";
import FOTO02 from "../../assets/img/imagens-conte/foto02.svg";
import FOTO03 from "../../assets/img/imagens-conte/foto03.svg";
import FOTO04 from "../../assets/img/imagens-conte/foto04.svg";
import LINHA from "../../assets/img/imagens-conte/linhainferior.svg";

const ImagensConte = () => {
  return (
    <section id="imagens">
      <div className="imagens-container">
        <img src={FOTO01} alt="" />
        <img src={FOTO02} alt="" />
        <img src={FOTO03} alt="" />
        <img src={FOTO04} alt="" />
      </div>
      <div className="imagens-linha">
        <img src={LINHA} alt="" />
      </div>
    </section>
  );
};

export default ImagensConte;
