import React, { useState } from "react";
import "./depoimentos-carousel-conte.css";
import { PiQuotesFill } from "react-icons/pi";

const slideData = [
  {
    text: "Comprei o 1 e já vou comprar o volume 2 já esperando que tenha o 3. Minha filha amou porque abre muito a visão da criança e ensina a raciocinar de formas diferentes.",
    name: "SUZANA RODRIGUES TISOCO CABRAL",
  },
  {
    text: "Muito útil para apoiar o trabalho pedagógico dos professores e professoras do Ensino Fundamental. Vou utilizar como material de apoio a oficinas de capacitação de professores na metodologia de resolução de problemas.",
    name: "YURIKO YAMAMOTO BALDIN",
  },
  {
    text: "Um livro super adequado para as crianças. Explora o raciocínio lógico e a curiosidade das crianças em aprender!! Adorei!",
    name: "TANIA RAMOS",
  },
];

const DepoimentosCarouselConte = () => {
  const [activePage, setActivePage] = useState(0);

  const goToSlide = (index) => {
    setActivePage(index);
  };

  let slidesPerPage = 1;
  if (window.innerWidth >= 900 && window.innerWidth < 1200) {
    slidesPerPage = 2;
  } else if (window.innerWidth >= 1200) {
    slidesPerPage = 3;
  }

  const totalPages = Math.ceil(slideData.length / slidesPerPage);

  const paginationIndices = Array.from({ length: totalPages }, (_, index) => index);

  // eslint-disable-next-line
  const activeIndex = activePage * slidesPerPage;

  return (
    <div className="slider-container-conte">
      <div className="slides-conte">
        {slideData.map((slide, index) => {
          const pageIndex = Math.floor(index / slidesPerPage);
          return (
            <article key={index} className={`slide-conte ${pageIndex === activePage ? "active" : ""}`}>
              <div className="slide-content-conte">
                <div className="slide-icon-conte">
                  <PiQuotesFill size={35} className="icon-conte" />
                </div>
                <div className="slide-text-conte">
                  <div className="slide-top-conte">
                    <p>{slide.text}</p>
                  </div>
                  <div className="slide-bottom-conte">
                    <h3>{slide.name}</h3>
                  </div>
                </div>
              </div>
            </article>
          );
        })}
      </div>
      <div className="pagination-conte">
        {paginationIndices.map((index) => (
          <span
            key={index}
            className={`pagination-dot-conte ${index === activePage ? "active" : ""}`}
            onClick={() => goToSlide(index)}
          />
        ))}
      </div>
    </div>
  );
};

export default DepoimentosCarouselConte;
