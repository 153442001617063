import React from "react";
import "./iniciativas.css";
import { PiHouseLine, PiMapPin, PiStudent } from "react-icons/pi";

import MEDALHAS from "../../assets/img/iniciativas/medalhas.png";

const Iniciativas = () => {
  return (
    <section id="iniciativas">
      <div className="container iniciativas-container">
        <div className="iniciativas-title">
          <h3>Nossas Iniciativas</h3>
        </div>
        <div className="iniciativas-content">
          <div className="iniciativas-left">
            <div className="iniciativas-left-img">
              <img src={MEDALHAS} alt="medalhas do canguru de matemática" />
            </div>
            <div className="iniciativas-left-info">
              <div className="iniciativas-card" id="escolas">
                <PiHouseLine size={35} color="#231f20" />
                <small>
                  Mais de
                  <br />
                  6800 escolas
                </small>
              </div>
              <div className="iniciativas-card" id="alunos">
                <PiStudent size={35} color="#231f20" />
                <small>
                  Mais de 1 milhão
                  <br />
                  de alunos
                </small>
              </div>
              <div className="iniciativas-card" id="estados">
                <PiMapPin size={35} color="#231f20" />
                <small>
                  Todos os estados
                  <br />
                  brasileiros
                </small>
              </div>
            </div>
          </div>
          <div className="iniciativas-right">
            <div className="iniciativas-right-txt">
              <h4>Concurso Canguru</h4>
              <p>
                O Concurso Canguru de Matemática é a maior competição
                internacional de Matemática do mundo destinada aos alunos do 3º
                ano do Ensino Fundamental até os do 3º ano do Ensino Médio.
                <br />
                <br />
                A competição teve origem na França e é administrada globalmente
                pela Associação Canguru sem Fronteiras (Association Kangourou
                sans Frontières - AKSF).
                <br />
                <br />O Concurso Canguru de Matemática é a{" "}
                <b>maior competição Internacional de Matemática do mundo</b>,
                com mais de<br/>6 milhões de participantes por ano em mais de 95
                países.
              </p>
            </div>
            <div className="btn iniciativas-btn">
              <a
                href="http://concurso.cangurudematematicabrasil.com.br/"
                target="blank"
              >
                Conheça
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Iniciativas;
