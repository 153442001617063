import React from "react";
import "./infos.css";
import { PiGlobeHemisphereWest, PiStudent } from "react-icons/pi";
import BRASIL from "../../assets/img/infos/brasil.svg";

const Infos = () => {
  return (
    <section id="infos">
      <div className="container infos-container">
        <div className="info-card" id="estudantes">
          <div className="info-icon" id="icon-estudantes">
            <PiStudent size={70} />
          </div>
          <div className="info-txt">
            <h5>+ 6 Milhões</h5>
            <p>
              de estudantes participantes
              <br /> a cada ano mundialmente
            </p>
          </div>
        </div>
        <div className="info-card" id="paises">
          <div className="info-icon" id="icon-paises">
            <PiGlobeHemisphereWest size={80} />
          </div>
          <div className="info-txt">
            <h5>Mais de 95</h5>
            <p>países associados</p>
          </div>
        </div>
        <div className="info-card" id="brasil">
          <div className="info-icon" id="icon-brasil">
            <img src={BRASIL} alt="Mapa do Brasil" />
          </div>
          <div className="info-txt">
            <h5>2,5 milhões</h5>
            <p>
              de estudantes
              <br /> participantes no Brasil
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Infos;
