import React from "react";
import "./comunidade.css";
import { PiArrowsOut, PiCheckSquare, PiExam, PiSmiley } from "react-icons/pi";

import MAPA from "../../assets/img/comunidade/mapa.svg";
import UNV01 from "../../assets/img/comunidade/01canberra.svg";
import UNV02 from "../../assets/img/comunidade/02gothenburg.svg";
import UNV03 from "../../assets/img/comunidade/03uk.svg";
import UNV04 from "../../assets/img/comunidade/04catalana.svg";
import UNV05 from "../../assets/img/comunidade/05fespm.svg";
import UNV06 from "../../assets/img/comunidade/06danmarks.svg";

const Comunidade = () => {
  return (
    <section id="comunidade">
      <div className="container comunidade-container">
        <div className="comunidade-top-txt">
          <h3>Comunidade Internacional</h3>
          <p>
            Fazemos parte de uma comunidade global presente em mais de 95 países, unidos pelo propósito de fortalecer a
            educação matemática na Educação Básica. Nossa missão inclui:
          </p>
        </div>

        <div className="comunidade-top-cards">
          <div className="comunidade-card" id="ampliar">
            <PiArrowsOut size={40} color="#FFD200" />
            <p>Ampliar o conhecimento matemático.</p>
          </div>
          <div className="comunidade-card" id="tornar">
            <PiCheckSquare size={40} color="#FFD200" />
            <p>Tornar o estudo da Matemática interessante e contextualizado.</p>
          </div>
          <div className="comunidade-card" id="aprimorar">
            <PiExam size={40} color="#FFD200" />
            <p>Aprimorar o ensino da Matemática em todos os níveis.</p>
          </div>
          <div className="comunidade-card" id="estimular">
            <PiSmiley size={40} color="#FFD200" />
            <p>Estimular o prazer e a satisfação intelectual na resolução de problemas matemáticos.</p>
          </div>
        </div>

        <div className="comunidade-mid-img">
          <img src={MAPA} alt="mapa ilustrativo" />
        </div>

        <div className="comunidade-bottom-img">
          <img src={UNV01} id="unv01" alt="logo de universidade" />
          <img src={UNV02} id="unv02" alt="logo de universidade" />
          <img src={UNV03} id="unv03" alt="logo de universidade" />
          <img src={UNV04} id="unv04" alt="logo de universidade" />
          <img src={UNV05} id="unv05" alt="logo de universidade" />
          <img src={UNV06} id="unv06" alt="logo de universidade" />
        </div>

        <div className="comunidade-bottom-txt">
          <p>
            Com o apoio de <font color="#49A942">mais de 200 professores apaixonados</font>, continuaremos trabalhando
            para o <font color="#009DDC">futuro da educação</font> matemática em todo o mundo.
          </p>
        </div>

        <div className="btn comunidade-btn">
          <a href="https://www.aksf.org" target="blank">
            www.aksf.org
          </a>
        </div>
      </div>
    </section>
  );
};

export default Comunidade;
