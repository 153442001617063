import React from "react";
import BannerConte from "../../components/banner-conte/BannerConte";
import Colecao from "../../components/colecao/Colecao";
import ProdutosConte from "../../components/produtos-conte/ProdutosConte";
import DepoimentosConte from "../../components/depoimentos-conte/DepoimentosConte";
import ImagensConte from "../../components/imagens-conte/ImagensConte";
import NossaLoja from "../../components/nossa-loja/NossaLoja";
import FooterConte from "../../components/footer-conte/FooterConte";

const ConteComAMatematica = () => {
  return (
    <>
      <BannerConte />
      <Colecao />
      <ProdutosConte />
      <DepoimentosConte />
      <ImagensConte />
      <NossaLoja />
      <FooterConte />
    </>
  );
};

export default ConteComAMatematica;
