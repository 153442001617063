import React from "react";
import "./depoimentos-conte.css";
import DepoimentosCarouselConte from "./depoimentoscarousel-conte/DepoimentosCarouselConte";

const DepoimentosConte = () => {
  return (
    <section id="depoimentos-conte">
      <div className="container depoimentos-container-conte">
        <div className="depoimentos-txt-conte">
          <h3>Depoimentos</h3>
        </div>

        <div className="depoimentos-carousel-conte">
          <DepoimentosCarouselConte />
        </div>
      </div>
    </section>
  );
};

export default DepoimentosConte;
