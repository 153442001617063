import React, { useEffect, useState } from "react";
import "./footer-conte.css";
import FooterConteMobile from "./footer-conte-mobile/FooterConteMobile";
import FooterConteWeb from "./footer-conte-web/FooterConteWeb";
import FooterConteWebLg from "./footer-conte-web-lg/FooterConteWebLg";

const FooterConte = () => {
  const [windowSize, setWindowSize] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowSize(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <footer id="footer-conte">
      {windowSize < 900 && <FooterConteMobile />}
      {windowSize >= 900 && windowSize < 1200 && <FooterConteWeb />}
      {windowSize >= 1200 && <FooterConteWebLg />}
    </footer>
  );
};

export default FooterConte;
