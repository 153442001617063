import React from "react";
import "./navbar.css";
import LOGOCANGURU from "../../assets/img/navbar/logo-canguru-de-matematica.svg";

const Navbar = () => {
  return (
    <header id="navbar">
      <nav className="container navbar-container">
        <div className="navbar-img" id="mobile-img">
          <a href="/">
            <img src={LOGOCANGURU} alt="Canguru de Matemática Brasil" />
          </a>
        </div>

        <div className="navbar-links">
          <div className="navbar-img" id="desk-img">
            <a href="/">
              <img src={LOGOCANGURU} alt="Canguru de Matemática Brasil" />
            </a>
          </div>

          <div className="navbar-missao">
            <a href="#missao">Nossa Missão</a>
          </div>

          <div className="navbar-comunidade">
            <a href="#comunidade">Comunidade</a>
          </div>

          <div className="navbar-iniciativas">
            <a href="#iniciativas">Iniciativas</a>
          </div>

          <div className="navbar-contecomamatematica">
            <a href="/contecomamatematica#conte">Conte com a Matemática</a>
          </div>

          <div className="navbar-btns">
            <div className="btn navbar-btn-concurso">
              <a
                href="http://concurso.cangurudematematicabrasil.com.br/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Concurso Canguru
              </a>
            </div>

            <div className="btn navbar-btn-experience">
              <a
                href="http://experience.cangurudematematicabrasil.com.br/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Canguru Experience
              </a>
            </div>

            <div className="btn navbar-btn-loja">
              <a
                href="https://nossaloja.upmat.com.br"
                target="_blank"
                rel="noopener noreferrer"
              >
                Nossa Loja
              </a>
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Navbar;
