import React from "react";
import "./nossa-loja.css";

import PRODUTOS from "../../assets/img/nossa-loja/produtos.png";

const NossaLoja = () => {
  return (
    <section id="nossa-loja">
      <div className="container nossa-loja-container">
        <div className="loja-left">
          <div className="loja-txt">
            <p>Nossa loja</p>
            <small>
              A diversão continua na nossa loja exclusiva com itens colecionáveis e produtos inspirados em matemática.
              Não perca nossas ofertas exclusivas e leve para casa um pedacinho do fascinante universo do conhecimento.
            </small>
          </div>
          <div className="btn loja-btn">
            <a href="https://nossaloja.upmat.com.br" target="blank">
              Acessar Loja
            </a>
          </div>
        </div>
        <div className="loja-right">
          <img src={PRODUTOS} alt="demonstração com vários produtos" />
        </div>
      </div>
    </section>
  );
};

export default NossaLoja;
