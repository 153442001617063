import React from "react";
import Navbar from "../../components/navbar/Navbar";
import Banner from "../../components/banner/Banner";
import Infos from "../../components/infos/Infos";
import Comunidade from "../../components/comunidade/Comunidade";
import Depoimentos from "../../components/depoimentos/Depoimentos";
import Missao from "../../components/missao/Missao";
import Iniciativas from "../../components/iniciativas/Iniciativas";
import Experience from "../../components/experience/Experience";
import Footer from "../../components/footer/Footer";

const Home = () => {
  return (
    <>
      <Navbar />
      <Banner />
      <Infos />
      <Missao />
      <Comunidade />
      <Iniciativas />
      <Experience />
      <Depoimentos />
      <Footer />
    </>
  );
};

export default Home;
