import React from "react";
import "./produtos-conte.css";
import { PiBaby, PiFlowerLotus, PiNote } from "react-icons/pi";

import LIVRO1VOL1 from "../../assets/img/produtos-conte/livro1-vol1.png";
import LIVRO2VOL1 from "../../assets/img/produtos-conte/livro2-vol1.png";

const ProdutosConte = () => {
  return (
    <section id="produtos">
      <div className="container produtos-container">
        <div className="produtos-card">
          <div className="card-top">
            <div className="card-top-left" id="item1">
              <div className="card-top-img">
                <img src={LIVRO1VOL1} alt="Livro Números e Geometria" />
              </div>
            </div>
            <div className="card-top-right">
              <div className="card-top-txt">
                <small id="book-type">Livro 1 | Volume 1</small>
                <h5 id="book-title">Números e Geometria</h5>
                <p id="book-descriprion">
                  Cada um dos eixos é composto por temas desenvolvidos por meio de mais de 340 atividades inéditas que
                  descrevem situações reais e cotidianas, convidando a refletir, investigar, observar e usar a
                  criatividade.
                </p>
              </div>
              <div className="card-top-btns">
                <div className="btn btn-card-loja">
                  <a href="https://nossaloja.upmat.com.br" target="blank">
                    Em breve na <b>Nossa Loja</b>
                  </a>
                </div>
                <div className="btn btn-card-amazon">
                  <a
                    href="https://www.amazon.com.br/Conte-com-Matemática-Números-Geometria/dp/6589004021/ref=sr_1_2"
                    target="blank"
                  >
                    Comprar na <b>Amazon</b>
                  </a>
                </div>
                {/* <div className="btn btn-card-saiba-mais">
                  <a href="" target="blank">
                    Saiba mais
                  </a>
                </div> */}
              </div>
              {/* <div className="card-top-resolucoes">
                <PiArrowCircleRightBold size={22} />
                <small>Acesse as resoluções comentadas</small>
              </div> */}
            </div>
          </div>
          <div className="card-bottom">
            <div className="info-left">
              <div className="left-icon">
                <PiBaby size={20} />
              </div>
              <small>
                Crianças com idades
                <br />
                entre 8 e 10 anos.
              </small>
            </div>
            <div className="info-center">
              <div className="center-icon">
                <PiFlowerLotus size={20} />
              </div>
              <small>Apoia e promove o pensar matemático.</small>
            </div>
            <div className="info-right">
              <div className="right-icon">
                <PiNote size={20} />
              </div>
              <small>
                Desenvolve as habilidades mobilizadas
                <br />
                na leitura de textos matemáticos.
              </small>
            </div>
          </div>
        </div>

        <div className="produtos-card">
          <div className="card-top">
            <div className="card-top-left" id="item2">
              <div className="card-top-img">
                <img src={LIVRO2VOL1} alt="Livro Álgebra e Grandezas e Medidas" />
              </div>
            </div>
            <div className="card-top-right">
              <div className="card-top-txt">
                <small id="book-type">Livro 2 | Volume 1</small>
                <h5 id="book-title">Álgebra e Grandezas e Medidas</h5>
                <p id="book-descriprion">
                  O livro traz mais de 245 atividades que, sempre que possível, descrevem situações reais que incentivam
                  a leitura, a reflexão, a investigação e a observação são pontos de partida para elaboração de
                  estratégias que levem à resolução.
                </p>
              </div>
              <div className="card-top-btns">
                <div className="btn btn-card-loja">
                  <a href="https://nossaloja.upmat.com.br/product-page/álgebra-e-grandezas-e-medidas" target="blank">
                    Comprar na <b>Nossa Loja</b>
                  </a>
                </div>
                <div className="btn btn-card-amazon">
                  <a
                    href="https://www.amazon.com.br/Conte-com-Matemática-Álgebra-Grandezas/dp/6589004048/ref=sr_1_1"
                    target="blank"
                  >
                    Comprar na <b>Amazon</b>
                  </a>
                </div>
                {/* <div className="btn btn-card-saiba-mais">
                  <a href="" target="blank">
                    Saiba mais
                  </a>
                </div> */}
              </div>
              {/* <div className="card-top-resolucoes">
                <PiArrowCircleRightBold size={22} />
                <small>Acesse as resoluções comentadas</small>
              </div> */}
            </div>
          </div>
          <div className="card-bottom">
            <div className="info-left">
              <div className="left-icon">
                <PiBaby size={20} />
              </div>
              <small>
                Crianças com idades
                <br />
                entre 8 e 10 anos.
              </small>
            </div>
            <div className="info-center">
              <div className="center-icon">
                <PiFlowerLotus size={20} />
              </div>
              <small>
                Torna o aprendizado da matemática
                <br />
                acessível ao maior número de pessoas.
              </small>
            </div>
            <div className="info-right">
              <div className="right-icon">
                <PiNote size={20} />
              </div>
              <small>
                Utiliza vários tipos de textos, como fotos,
                <br />
                desenhos, tabelas, gráficos e outros.
              </small>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProdutosConte;
