import React, { useState } from "react";
import "./depoimentos-carousel.css";
import { PiQuotesFill } from "react-icons/pi";

const slideData = [
  {
    text: "Acreditamos muito no Concurso Canguru de Matemática para incentivarmos nossos estudantes ao gosto pela Matemática. Por isso, participamos desse evento desde o ano de 2011 com grande repercussão em nossa escola, desde o processo online de inscrição até a cerimônia de premiação com as famílias.",
    name: "José Marcio",
    ocupation: "Colégio Santo Agostinho - SP",
  },
  {
    text: "Eu sou professor de matemática do Colégio Integrado Jaó, integrante do grupo Somos Educação. Fizemos da participação do Canguru Matemática Brasil uma ferramenta de motivação e entusiasmo na nossa escola! Os alunos vibram em poder fazê-la! Aqui temos aulas vespertinas gratuitas para permitir que os alunos conheçam questões que caíram nas edições anteriores e se encantem pelo gosto matemático. Parabéns aos organizadores, fizemos juntos essa viagem ao maravilhoso universo dos problemas matemáticos! Show demais!!!",
    name: "Tales Mazzo",
    ocupation: "Colégio Integrado Jaó - GO",
  },
  {
    text: "Foi a primeira olimpíada que minha aluna que eu acompanho desde o 4° ano do fundamental fez. Ela está no 6° ano e foi a única da Escola dela que tirou medalha de Ouro. Muito orgulho da minha aluna. Queria que as escolas fizessem um maior esforço para estimular mais seus alunos.",
    name: "Léa Lucena",
    ocupation: "Colégio Caminho do Saber - SP",
  },
];

const DepoimentosCarousel = () => {
  const [activePage, setActivePage] = useState(0);

  const goToSlide = (index) => {
    setActivePage(index);
  };

  let slidesPerPage = 1;
  if (window.innerWidth >= 900 && window.innerWidth < 1200) {
    slidesPerPage = 2;
  } else if (window.innerWidth >= 1200) {
    slidesPerPage = 3;
  }

  const totalPages = Math.ceil(slideData.length / slidesPerPage);

  const paginationIndices = Array.from({ length: totalPages }, (_, index) => index);

  // eslint-disable-next-line
  const activeIndex = activePage * slidesPerPage;

  return (
    <div className="slider-container">
      <div className="slides">
        {slideData.map((slide, index) => {
          const pageIndex = Math.floor(index / slidesPerPage);
          return (
            <article key={index} className={`slide ${pageIndex === activePage ? "active" : ""}`}>
              <div className="slide-content">
                <div className="slide-icon">
                  <PiQuotesFill size={35} className="icon" />
                </div>
                <div className="slide-text">
                  <div className="slide-top">
                    <p>{slide.text}</p>
                  </div>
                  <div className="slide-bottom">
                    <h3>{slide.name}</h3>
                    <small>{slide.ocupation}</small>
                  </div>
                </div>
              </div>
            </article>
          );
        })}
      </div>
      <div className="pagination">
        {paginationIndices.map((index) => (
          <span
            key={index}
            className={`pagination-dot ${index === activePage ? "active" : ""}`}
            onClick={() => goToSlide(index)}
          />
        ))}
      </div>
    </div>
  );
};

export default DepoimentosCarousel;
