import React from "react";
import "./experience.css";
import { PiBookOpenText, PiExam, PiNote } from "react-icons/pi";

import MOCKUP from "../../assets/img/experience/mockup.png";

const Experience = () => {
  return (
    <section id="experience">
      <div className="container experience-container">
        <div className="experience-left">
          <div className="experience-left-txt">
            <h4>Canguru Experience</h4>
            <p>
              <b>
                Descubra o Canguru Experience: a Matemática que Vai Além do
                Concurso
              </b>
              <br />
              <br />
              O Canguru Experience é uma iniciativa do Canguru de Matemática
              Brasil que amplia e aprofunda a participação de escolas,
              professores e alunos para além do período de realização das provas
              do Concurso.
              <br />
              <br />A plataforma do{" "}
              <b>Canguru Experience é exclusiva para os professores</b> e nela
              eles encontrarão: Aula Canguru (roteiros para a sala de aula),
              sondagens e jornadas de aprendizagem e de preparação para o
              Concurso Canguru.
            </p>
          </div>
          <div className="btn experience-btn">
            <a
              href="https://experience.cangurudematematicabrasil.com.br"
              target="_blank"
              rel="noopener noreferrer"
            >
              Experimente Grátis Por 7 Dias!
            </a>
          </div>
        </div>

        <div className="experience-right">
          <div className="experience-right-img">
            <img
              src={MOCKUP}
              alt="simulação do canguru experience em um notebook"
            />
          </div>
          <div className="experience-right-info">
            <div className="experience-card" id="escolas">
              <PiBookOpenText size={35} color="#231f20" />
              <small>
                Acervo
                <br />
                Canguru
              </small>
            </div>
            <div className="experience-card" id="alunos">
              <PiExam size={35} color="#231f20" />
              <small>
                Trilhas de
                <br />
                Aprendizagem
              </small>
            </div>
            <div className="experience-card" id="estados">
              <PiNote size={35} color="#231f20" />
              <small>
                Materiais exclusivos de
                <br />
                preparação para o concurso
              </small>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Experience;
