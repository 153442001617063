import React, { useState, useEffect } from "react";
import "./banner.css";
import { PiCaretLeftBold, PiCaretRightBold } from "react-icons/pi";

import BGLEFT from "../../assets/img/banner/bg-left.svg";
import BGRIGHT from "../../assets/img/banner/bg-right.svg";
import MENINAECANGURU from "../../assets/img/banner/menina-e-canguru.svg";
import CANGURUEMENINA from "../../assets/img/banner/canguru-e-menina.svg";

const Banner = () => {
  const banners = ["banner1", "banner2"];
  const [currentBanner, setCurrentBanner] = useState(0);

  const nextBanner = () => {
    setCurrentBanner((prevBanner) => (prevBanner + 1) % banners.length);
  };

  const prevBanner = () => {
    setCurrentBanner(
      (prevBanner) => (prevBanner - 1 + banners.length) % banners.length
    );
  };

  useEffect(() => {
    const timer = setInterval(nextBanner, 5000);
    return () => clearInterval(timer);
    // eslint-disable-next-line
  }, [currentBanner]);

  return (
    <div id="banner">
      <div className="bg-img-left">
        <img src={BGLEFT} alt="imagem de background" />
      </div>
      <div className="bg-img-right">
        <img src={BGRIGHT} alt="imagem de background" />
      </div>

      {currentBanner === 0 && (
        <div className="container banner-container banner1">
          <div className="banner-txt1">
            <h2>
              Matemática <font color="#49A942">acessível</font>,{" "}
              <font color="#FFD200">engajadora</font>
              <br /> e motivadora para todos!
            </h2>
            <p>
              Mais de 2,5 milhões de estudantes em todo o Brasil já tiveram a
              oportunidade de experimentar o Canguru de Matemática, descobrindo
              como a Matemática pode ser Divertida, Desafiadora e Diferente.
            </p>

            <div className="btn banner-btn1">
              <p>Inscrições Encerradas</p>
            </div>
          </div>

          <div className="banner-img1">
            <img src={MENINAECANGURU} alt="Canguru e menino com mochila" />
          </div>

          <div className="slide-btn1" onClick={nextBanner}>
            <PiCaretRightBold size={20} />
          </div>
        </div>
      )}

      {currentBanner === 1 && (
        <div className="container banner-container banner2">
          <div className="slide-btn2" onClick={prevBanner}>
            <PiCaretLeftBold size={20} />
          </div>
          <div className="banner-txt2">
            <h2>Seja um Patrocinador</h2>
            <p>CANGURU DE MATEMÁTICA</p>
            <small>
              Inspire a paixão pela matemática! Participe do Patrocínio do
              Concurso Canguru de Matemática 2024 para escolas públicas e ajude
              no engajamento de milhares de alunos.
            </small>

            <div className="btn banner-btn2">
              <a
                href="https://2024.cangurudematematicabrasil.com.br/quero-apoiar"
                target="blank"
              >
                Quero Apoiar
              </a>
            </div>
          </div>

          <div className="banner-img2">
            <img src={CANGURUEMENINA} alt="Canguru e menina" />
          </div>
        </div>
      )}
    </div>
  );
};

export default Banner;
