import React from "react";
import "./footer-web.css";
import LOGOUPMAT from "../../../assets/img/footer/logo-upmat-preto.svg";
import LOGOGARAGEM from "../../../assets/img/footer/logo-garagem.svg";
import CANGURU from "../../../assets/img/footer/canguru-cruzado.svg";

import {
  PiEnvelopeSimple,
  PiInstagramLogoFill,
  PiFacebookLogoFill,
  PiYoutubeLogoFill,
  PiCopyright,
} from "react-icons/pi";

const FooterWeb = () => {
  return (
    <div className="footer-container-web">
      <div className="footer-web-top">
        <div className="footer-realizacao">
          <h3>Realização e Organização</h3>

          <div className="footer-logos">
            <img src={LOGOUPMAT} alt="Upmat Educacional" />
            <img src={LOGOGARAGEM} alt="Garagem BNDES" />
          </div>

          <div className="footer-mail">
            <PiEnvelopeSimple />
            <small>contato@cangurudematematicabrasil.com.br</small>
          </div>
        </div>

        <div className="footer-canguru">
          <h3>Canguru de Matemática Brasil</h3>
          <div className="footer-links">
            <ul>
              <li>
                <a href="http://concurso.cangurudematematicabrasil.com.br/" target="blank">
                  <small>Concurso Canguru</small>
                </a>
              </li>
              <li>
                <a href="https://experience.cangurudematematicabrasil.com.br" target="blank">
                  <small>Canguru Experience</small>
                </a>
              </li>
              <li>
                <a href="https://cangurudematematicabrasil.com.br/contecomamatematica" target="blank">
                  <small>Conte com a matemática</small>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="footer-img">
        <img src={CANGURU} alt="canguru feliz piscando um olho" />
      </div>

      <div className="footer-web-bottom">
        <div className="footer-conheca">
          <h3>Conheça também</h3>
          <div className="footer-links">
            <ul>
              <li>
                <a href="https://nossaloja.upmat.com.br" target="blank">
                  <small>Nossa Loja</small>
                </a>
              </li>
              <li>
                <a href="https://bebrasbrasil.com.br" target="blank">
                  <small>Bebras Brasil</small>
                </a>
              </li>
              <li>
                <a href="https://desafio.bebrasbrasil.com.br" target="blank">
                  <small>Desafio Bebras</small>
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div className="footer-acompanhe">
          <h3>Acompanhe</h3>
          <div className="footer-icons">
            <a href="https://www.instagram.com/cangurudematematicabrasil/" target="blank">
              <PiInstagramLogoFill size={28} />
            </a>

            <a href="https://www.facebook.com/cangurudematematicabrasil" target="blank">
              <PiFacebookLogoFill size={28} />
            </a>

            <a href="https://www.youtube.com/c/CangurudeMatemáticaBrasiloficial" target="blank">
              <PiYoutubeLogoFill size={28} />
            </a>
          </div>
        </div>
      </div>

      <div className="copyright-container">
        <PiCopyright />
        <small>UpMat Brasil Educacional Ltda | Todos os direitos reservados.</small>
      </div>
    </div>
  );
};

export default FooterWeb;
