import React from "react";
import "./banner-corte.css";
import { PiArrowCircleLeftFill } from "react-icons/pi";

import BG from "../../assets/img/banner-conte/formas.svg";
import CONTE from "../../assets/img/banner-conte/conte-com.svg";
import CANGURUEMENINO from "../../assets/img/banner-conte/canguru-e-menino.svg";

const BannerConte = () => {
  return (
    <div className="header-conte" id="conte">
      <div className="bg-img">
        <img src={BG} alt="imagem de background" />
      </div>
      <a href="/" className="container voltar">
        <PiArrowCircleLeftFill size={20} color="#fff" />
        <small>Voltar para página inicial</small>
      </a>
      <div className="container header-conte-container">
        <div className="header-content">
          <div className="left-img">
            <img src={CONTE} alt="conte com a matemática" />
          </div>
          <div className="header-txt">
            <small>Matemática 3D</small>
            <p>Diferente, Desafiadora & Divertida</p>
            <div className="btn btn-resolucoes">
              <a href="https://ccm.cangurudematematicabrasil.com.br/" target="blank">
                Acesse as Resoluções
              </a>
            </div>
          </div>
          <div className="right-img">
            <img src={CANGURUEMENINO} alt="canguru acenando e menino segurando um livro" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BannerConte;
